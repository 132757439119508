import React from "react"

import { FaInstagram, FaFacebook, FaEnvelope } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/contact.scss"

const Contact = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <div className="contact">
      <a
        href="https://instagram.com/haroldrichter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href="https://facebook.com/haroldrichter.art"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </a>
      <a
        href="mailto:harolddanielrichter@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaEnvelope />
      </a>
    </div>
  </Layout>
)

export default Contact
